/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-link {
  @apply block p-3 mb-4 rounded-lg text-gray-300 py-2 hover:bg-gray-800 transition duration-300;

  &.active {
    @apply bg-white text-black;

    &:hover {
      @apply text-black;
    }
  }

  &:hover {
    @apply text-white;
  }

  &.divider {
    @apply mb-12;
  }
}

@layer base {
  a {
    @apply text-blue-500;
    @apply break-all;

    &:hover {
      @apply text-blue-700;
    }
  }
}
